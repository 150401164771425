<template>
  <div class="box">
    <img class="top" src="../../assets/ShopImg/bg.png" alt="" />
    <!-- 暂留状态栏 -->
    <!-- <div style="height: 25px"></div> -->
    <!-- 搜索 -->
    <van-search
      v-model="value"
      shape="round"
      background="#FE2F19"
      placeholder="搜索"
      @search="Search"
    />
    <!-- banner -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(b, i) in banners" :key="i">
        <img :src="b.ImgUrl" alt="" @click="bannnerGoTo(b)" />
      </van-swipe-item>
    </van-swipe>
    <!-- 标签 -->
    <div class="icon_div">
      <router-link
        class="icon"
        v-for="(item, i) in categoryList"
        :key="i"
        :to="'/shop/goods/list?categoryId=' + item.id"
      >
        <img class="icon_img" :src="item.li_attr.ImageUrl" alt="" />
        <div class="icon_name">{{ item.text }}</div>
      </router-link>
      <router-link class="icon" to="/shop/pointsmall/index">
        <img class="icon_img" src="../../assets/ShopImg/icon6.png" alt="" />
        <div class="icon_name">积分商城</div>
      </router-link>
    </div>
    <!-- 广告 -->
    <div class="bg_white"></div>
    <!-- <img class="gg" src="../../assets/ShopImg/gg.png" alt=""> -->

    <!-- 热门推荐 -->
    <div class="hot_comment">
      <div class="hot_comment_pd">
        <!-- 标题 -->
        <!-- <div class="hot_title">
          <div class="title">热门推荐</div>
          <router-link class="more" to="/shop/goods/list/">
            <span>查看更多</span>
            <img src="../../assets/ShopImg/right.png" alt="">
          </router-link>
        </div> -->
        <!-- 内容 -->

        <!-- 热门标签 -->
        <Lab name="精选"></Lab>
        <div class="hot_content">
          <router-link
            class="hot_conntent_div"
            v-for="(item, i) in goodsHotList"
            :key="i"
            :to="'/shop/goods/detail/' + item.Id"
          >
            <img :src="item.CoverImg" alt="" />
            <div
              :class="[
                item.GoodsName.length >= 7
                  ? 'hot_conntent_div_sub'
                  : 'hot_conntent_div_sub2',
              ]"
            >
              {{ item.GoodsName }}
            </div>
            <div class="hot_conntent_div_money">
              <span>￥</span><span>{{ item.DefaultPrice }}</span>
              <span>￥{{ item.DefaultOriginalPrice }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 为你推荐 -->

    <div class="hot_comment">
      <div class="hot_comment_pd">
        <!-- 标题 -->
        <!-- <div class="hot_title">
          <div class="title">为你推荐</div>
          <div class="more">
            <span>查看更多</span>
            <img src="../../assets/ShopImg/right.png" alt="">
          </div>
        </div> -->
        <Lab name="精选"></Lab>
      </div>
      <!-- 内容 -->
      <div class="pububox">
        <!-- 
            v-for="(item, i) in goodsRecommendList"
            :key="i"
            :style="{ float: i % 2 == 1 ? 'right' : 'left' }" -->

        <!-- <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="postQeryShopGoodsList"
          v-if="goodsRecommendList.length > 0"
        > -->
        <div class="hot_conntent">
          <waterfall
            :col="wCol"
            :data="goodsRecommendList"
            @loadmore="loadmore"
          >
            <template>
              <div v-for="(item, i) in goodsRecommendList" :key="i">
                <div class="for_you">
                  <router-link :to="'/shop/goods/detail/' + item.Id">
                    <img :src="item.CoverImg" alt="" />
                    <div
                      class="
                        hot_conntent_div_money
                        for_you_money
                        hot_conntent_money
                      "
                    >
                      <span>￥</span><span>{{ item.DefaultPrice }}</span>
                      <span>￥{{ item.DefaultOriginalPrice }}</span>
                    </div>
                    <div class="title decimalPoint-2">
                      {{ item.GoodsName }}
                    </div>
                  </router-link>
                </div>
              </div>
            </template>
          </waterfall>
        </div>
        <div
          v-if="finished"
          style="
            color: #969799;
            font-size: 14px;
            line-height: 50px;
            text-align: center;
          "
        >
          没有更多了
        </div>
        <!-- </van-list> -->
        <div v-if="goodsRecommendList.length <= 0">
          <nocontent name="暂无数据"></nocontent>
        </div>
      </div>
    </div>

    <!-- <img
      class="tops"
      @click="scrolltop"
      src="../../assets/ShopImg/top.png"
      alt=""
    /> -->
  </div>
</template>

<script>
import {
  queryPcbannerList,
  qeryShopGoodsList,
  queryGoodsCategoryTree,
} from "@/api/shop";
import { queryStudentInfoById } from "@/api/bbs";
import Lab from "../shop/components/label";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    Lab, nocontent
  },
  data () {
    return {
      wCol: 2,
      value: "",
      list: [
        {
          img: require("../../assets/ShopImg/icon1.png"),
          name: "艺术品",
          to: "/shop/pointsmall/index",
        },
        {
          img: require("../../assets/ShopImg/icon2.png"),
          name: "文创品",
          to: "/shop/pointsmall/index",
        },
        {
          img: require("../../assets/ShopImg/icon3.png"),
          name: "画具画材",
          to: "/shop/pointsmall/index",
        },
        {
          img: require("../../assets/ShopImg/icon4.png"),
          name: "教材图书",
          to: "/shop/pointsmall/index",
        },
        {
          img: require("../../assets/ShopImg/icon5.png"),
          name: "考试必备",
          to: "/shop/pointsmall/index",
        },
        {
          img: require("../../assets/ShopImg/icon6.png"),
          name: "积分商城",
          to: "/shop/pointsmall/index",
        },
      ],
      isLoad: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      goodsHotList: [],
      goodsRecommendList: [],
      categoryList: [],
      userInfo: {},
      banners: [],
      isSetInitStyle: true,
    };
  },
  created () {
    this.getQueryBanners();
    this.postQeryHotShopGoodsList();
    this.postQeryShopGoodsCategoryList();
    this.postQeryShopGoodsList();
    // window.addEventListener('scroll', this.listenScroll);
  },
  mounted () {
    if (this.$route.query.UserId == '') {
      localStorage.removeItem("userInfo");
    }
    else if (this.$route.query.UserId != undefined) {
      this.userInfo.Id = this.$route.query.UserId;
      this.getQueryUserInfo();
    }
    // window.userIDResultByIos = this.userIDResultByIos;
    // this.callApp();
  },
  methods: {
    loadmore () {
      this.pageIndex++
      this.postQeryShopGoodsList();
    },
    // 初始化卡片样式
    initCardStyle (props) {
      if (this.isSetInitStyle) {
        return {
          width: `${props.data.itemWidth}px`,
          height: `${((props.data.itemWidth - 20) / props.data.width) *
            props.data.height}px`,
          // backgroundColor: props.data.blankColor
        };
      }
    },
    // banner跳转
    bannnerGoTo (obj) {
      if (obj.GoodsId) {
        this.$router.push({
          path: "/shop/goods/detail/" + obj.GoodsId
        });
      }
    },
    callByIosParam (jsonStr) {
      this.$message.error("callByIosParam接收到的参数：" + jsonStr);
    },
    userIDResultByIos (jsonStr) {
      this.$message.error("userIDResultByIos接收到的参数:" + jsonStr);
      // if (jsonStr != "") {
      //   this.userInfo.Id =JSON.parse(jsonStr).data;
      //   this.getQueryUserInfo();
      // } else {
      //   this.$message.error("登录失败");
      // }
    },
    callApp () {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(JSON.stringify({ action: "callIosGetUserID" }));
        // window.webkit.messageHandlers.callIos.postMessage(JSON.stringify({action: "callIosGetUserID"}));
      }
    },
    // 用户详情
    async getQueryUserInfo () {
      // this.$message.error("调获取用户信息接口："+this.userInfo.Id);
      const res = await queryStudentInfoById(this.userInfo.Id);
      this.userInfo = res.response;
      localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    },
    // 分类列表数据
    async postQeryShopGoodsCategoryList () {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      this.categoryList = res.response;
      // console.log(this.categoryList, 888);
    },
    // 列表数据
    async postQeryShopGoodsList () {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.IsRecommend = 1;
      const res = await qeryShopGoodsList(parm);
      this.goodsRecommendList = this.goodsRecommendList.concat(res.data);
      // this.pageIndex++;
      // // 加载状态结束
      // this.loading = false;
      // // 数据全部加载完成
      if (this.goodsRecommendList.length >= res.dataCount) {
        this.finished = true;
      }
      // this.isLoad = false;
      // if (res.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    // 列表数据
    async postQeryHotShopGoodsList () {
      let parm = {};
      parm.pageIndex = 1;
      parm.pageSize = this.pageSize;
      parm.IsHot = 1;
      const res = await qeryShopGoodsList(parm);
      this.goodsHotList = res.data;
      this.isLoad = false;
    },
    // 列表数据
    async getQueryBanners () {
      let parm = "?pageIndex=1&pageSize=100&category=3";
      const res = await queryPcbannerList(parm);
      this.banners = res.response.data;
    },
    //滚动
    listenScroll () {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          that.postQeryShopGoodsList();
        }
      }
    },
    scrolltop () {
      let timer = setInterval(() => {
        // 获取滚动条高度
        let wTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let speed = Math.floor(-wTop / 5);
        // 上升过程中逐步获取滚动条高度
        document.documentElement.scrollTop = document.body.scrollTop =
          wTop + speed;
        // 到顶部时.停止
        if (wTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    Search () {
      // console.log(this.value);
      this.$router.push({
        path: "/shop/goods/list",
        query: {
          key: this.value,
        },
      });
    },
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>


<style lang="scss" scoped>
* {
  font-family: PingFang SC;
}
.box {
}
.top {
  width: 100%;
  height: 210px;
  z-index: -1;
  position: absolute;
  top: 0;
}

.icon_div {
  width: 92%;
  margin: 0 auto;
  background: #fff;
  min-height: 105px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: center;
  text-align: center;
  box-shadow: 2px 3px 8px rgba(132, 130, 130, 0.16);
  padding: 0 0 15px;
  .icon {
    width: 25%;
    padding-top: 15px;
    .icon_img {
      // width: 32px;
      height: 32px;
      display: inline-block;
    }

    .icon_name {
      margin-top: 5px;
      font-size: 14px;
      color: #333333;
    }
  }
}

.bg_white {
  width: 100%;
  height: 40vh;
  background: #f5f5f5;
  position: absolute;
  z-index: -10;
  margin-top: -93px;
}

.gg {
  width: 92%;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.hot_comment {
  width: 100%;
  background: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;

  .hot_comment_pd {
    width: 92%;
    margin-left: 4%;
  }

  .hot_title {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 16px;
      color: #333333;
      font-weight: 800;
    }

    .more {
      font-size: 12px;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .hot_content {
    overflow-x: auto;
    padding-bottom: 5px;
    width: 100%;
    display: flex;

    .hot_conntent_div:last-child {
      margin-right: 0px;
    }

    .hot_conntent_div {
      display: inline-block;
      width: 7.5rem;
      flex: 0 0 7.5rem;
      margin-right: 20px;
      background: #ffffff;
      border-radius: 10px;
      img {
        width: 100%;
        height: 120px;
        border-top-left-radius: 6px !important;
        border-top-right-radius: 6px !important;
      }
      // }
    }
  }

  .hot_content::-webkit-scrollbar {
    display: none;
  }
}

.hot_conntent_div_money span:nth-child(1) {
  color: #fe4a4a;
  font-size: 10px;
}

.hot_conntent_div_money span:nth-child(2) {
  color: #fe4a4a;
  font-weight: bold;
  font-size: 15px;
}

.hot_conntent_div_money span:nth-child(3) {
  color: #c9c9c9;
  text-decoration: line-through;
  font-weight: bold;
  font-size: 10px;
}

.hot_conntent_div_sub {
  // width: 98px;
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 10px auto 0px;
}
.hot_conntent_div_sub2 {
  // width: 98px;
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 20px auto 0px;
}
.hot_conntent_div_money {
  width: 98px;
  height: 40px;
  margin: 0 auto;
}
.for_you {
  // width: 50%;
  margin-bottom: 15px;
  overflow: hidden;
  img {
    width: 100%;
    margin: 0 auto;
    border-radius: 6px;
  }

  .for_you_money {
    height: 30px;
    line-height: 30px;
    float: left;
  }
}

.tops {
  width: 66x;
  height: 66px;
  border-radius: 50%;
  position: fixed;
  top: 75%;
  left: 82%;
}
.my-swipe {
  width: 92%;
  margin-left: 4%;
  margin-top: 10px;
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    // height: 160px;
    text-align: center;
    // background-color: #39a9ed;
    img {
      width: 100%;
      // height: 100%;
      // height: 160px;
      border-radius: 8px;
    }
  }
}
/deep/.waterfull-grid {
  background: none !important;
  .waterfull-item-box {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    padding-bottom: 10px;
    .hot_conntent_money {
      display: block;
      float: none;
      margin: 0;
      width: clac(100% - 20);
      padding: 0 10px;
    }
    .title {
      padding: 0 10px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
}
.hot_conntent {
  // padding: 0 15px;
  /deep/.vue-waterfall {
    .vue-waterfall-column {
      width: 44% !important;
      margin-left: 4%;
      .for_you {
        background-color: #fff;
        padding-bottom: 10px;
        border-radius: 10px;
      }
      .hot_conntent_money {
        display: block;
        float: none;
        margin: 0;
        padding: 0 10px;
      }
      .title {
        padding: 0 10px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
}
</style>