<template>
    <div>
        <div class="shop-label">
            <div class="left-font">{{name}}</div>
            <!-- <router-link class="more" > -->
            <div class="right-font" @click="todetail">
                查看更多
            </div>
             <!-- </router-link> -->
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: {
        type: String,
        default: 'hello world'
        }
  },
    methods:{
        todetail(){
             this.$router.push({
               path: `/shop/goods/list`,
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .shop-label{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .left-font{
            color: #333333;
            font-size: 16px;
            font-weight: 800;
        }
        .right-font{
            background-image:url('../../../assets/ShopImg/right.png');
            background-repeat: no-repeat;
            background-size: 18px 18px; 
            font-size: 12px;
            margin-right:-11px;
            color: #333333;
            width: 80px;
            background-position: 87% 16%;
        }
    }
</style>